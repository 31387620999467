import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getInitialData } from '../utils/firebase';
import { receiveNews } from './news';
import { receiveBreakingNews } from './breakingNews';
import { receiveCities } from './cities';

export function handleInitialData () {
  return (dispatch) => {
    dispatch(showLoading())
    return getInitialData()
        .then(({ cities, news, bNews }) => {
            let Cresult = [];
			cities.forEach((doc) => {
				let cit = {id: doc.id, ...doc.data()}
				Cresult.push(cit)
			})
			let Nresult = news.data();
            let breakingNews = bNews.data();

            dispatch(receiveCities(Cresult))
            dispatch(receiveNews(Nresult))
            dispatch(receiveBreakingNews(breakingNews))
            dispatch(hideLoading())
        }).catch((error) => {
            dispatch(receiveCities(undefined,error))
            dispatch(receiveNews(undefined,error))
            dispatch(receiveBreakingNews(undefined,error))
            dispatch(hideLoading())
        })
    }
} 