import React , { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Home from './home'
import Control from './control'
import '../App.css';
import { handleInitialData } from '../store/actionCreators/shared';

class App extends Component {
	componentDidMount() {
        this.props.dispatch(handleInitialData())
    }
    
    state = {

    }
	
	render() {
		// console.log(this.props)
        return (
			<React.Fragment>
				<Switch>
					<Route path="/" exact component={() => 
						<Home /> 
					} />
					<Route path="/control" exact component={Control} />
				</Switch>
			</React.Fragment>
		);
	}
	
}

function mapStateToProps ({ cities }) {
	return {
        cities,
	}
}

export default connect(mapStateToProps)(App)
