import { getAllCities, updateCity, getCity, updateCities, getRealtimeUpdates } from '../utils/firebase';

export const GET_CITIES = 'GET_CITIES';
export const UPDATE_CITIES = 'UPDATE_CITIES';
export const UPDATE_CITY = 'UPDATE_CITY';
export const GET_UPDATES = 'GET_UPDATES';

export function receiveCities (cities, error=null) {
    return {
        type: GET_CITIES,
        cities,
        error
    }
}

function submitUpdateCities (cities, error=null) {
    return {
        type: UPDATE_CITIES,
        cities,
        error
    }
}

function submitUpdateCity (cities, error=null) {
    return {
        type: UPDATE_CITY,
        cities,
        error
    }
}

export function handleSubmitUpdateCity(city) {
    return (dispatch) => {
        return updateCity(city)
        .then(() => {
            return getAllCities()
            .then(cities => {
                let result = [];
                cities.forEach((doc) => {
                    result.push(doc.data())
                })
                return dispatch(submitUpdateCity(result)) 
            })     
        }).catch(error => {
            return getAllCities()
            .then(cities => {
                let result = [];
                cities.forEach((doc) => {
                    result.push(doc.data())
                })
                return dispatch(submitUpdateCity(result,error))     
            })
        })
    }
}

export function handleSubmitUpdateCities (cities) {
    return (dispatch) => {
        return updateCities(cities)
        .then((result) => {
            console.log(result)
            return dispatch(submitUpdateCities(cities))          
        }).catch((error) => {
            return dispatch(submitUpdateCities(undefined,error))           
        }) 
    }
}

export function handleSubmitGetCity (city) {
    return () => {
        return getCity(city)
    }
}

export function handleSubmitGetUpdates(city) {
    return () => {
        return getRealtimeUpdates(city)
    }
}

/*
export function handleReceiveAllCities () {
    return (dispatch) => {
        dispatch(showLoading())
        return getAllCities()
          .then((cities) => {
            let result = [];
            cities.forEach((doc) => {
                result.push(doc.data())
            })
            dispatch(hideLoading())
            return dispatch(receiveCities(result))
        }).catch((error) => {
            dispatch(hideLoading())
            return dispatch(receiveCities(undefined,error))
        })
    }
} 

export function handleReceiveShownCities () {
    return (dispatch) => {
        dispatch(showLoading())
        return getShownCities()
          .then((cities) => {
            let result = [];
            cities.forEach((doc) => {
                result.push(doc.data())
            })
            dispatch(hideLoading())
            return dispatch(receiveCities(result))
        }).catch((error) => {
            dispatch(hideLoading())
            return dispatch(receiveCities(undefined,error))
        })
    }
} 
*/

