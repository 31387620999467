import { showLoading, hideLoading } from 'react-redux-loading-bar';
import firebase from "firebase/app";
import "firebase/auth";

import { signIn, logout } from '../utils/firebase';

export const SET_AUTHED_USER = 'SET_AUTHED_USER';

function setAuthedUser (user, error=null) {
    return {
        type: SET_AUTHED_USER,
        user,
        error
    }
}

export function handleSetAuthedUser () {
    return (dispatch) => {
        dispatch(showLoading())
        return firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                dispatch(hideLoading())
                return dispatch(setAuthedUser(user))
            } else {
                dispatch(hideLoading())
                return dispatch(setAuthedUser(null))
            }
        })
    }
}

export function handleSignIn (email,password) {
    return (dispatch) => {
        dispatch(showLoading())
        return signIn(email,password)
        .then((userCredential) => {
            dispatch(hideLoading())
            return dispatch(setAuthedUser(userCredential.user))
        })
        .catch((error) => {
            dispatch(hideLoading())
            return dispatch(setAuthedUser(null,error))
        })
        
    }
}

export function handleLogout () {
    return (dispatch) => {
        return logout()
        .then(() => dispatch(setAuthedUser(null)))
    }
}