import { GET_BREAKING_NEWS, UPDATE_BREAKING_NEWS } from '../actionCreators/breakingNews';

export default function breakingNews (state = null, action) {
    switch (action.type) {
        case GET_BREAKING_NEWS :
            return action.breakingNews;
        case UPDATE_BREAKING_NEWS :
            return action.breakingNews === undefined ? state : action.breakingNews;
        default :
            return state
    }
} 