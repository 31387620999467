import { updateBreakingNews, getRealtimeBreakingNews } from '../utils/firebase';

export const GET_BREAKING_NEWS = 'GET_BREAKING_NEWS';
export const UPDATE_BREAKING_NEWS = 'UPDATE_BREAKING_NEWS';

export function receiveBreakingNews (breakingNews, error=null) {
    return {
        type: GET_BREAKING_NEWS,
        breakingNews,
        error
    }
}

function submitUpdateNews (breakingNews, error=null) {
    return {
        type: UPDATE_BREAKING_NEWS,
        breakingNews,
        error
    }
}

export function handleSubmitUpdateBreakingNews(breakingNews) {
    return (dispatch) => {
        return updateBreakingNews(breakingNews)
        .then(() => {
            return dispatch(submitUpdateNews(breakingNews))    
        }).catch(error => {
            return dispatch(submitUpdateNews(undefined,error))  
        })
    }
}

export function handleSubmitGetBreakingNewsUpdates() {
    return () => {
        return getRealtimeBreakingNews()
    }
}

