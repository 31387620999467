import { updateNews, getRealtimeNews } from '../utils/firebase';

export const GET_NEWS = 'GET_NEWS';
export const UPDATE_NEWS = 'UPDATE_NEWS';

export function receiveNews (news, error=null) {
    return {
        type: GET_NEWS,
        news,
        error
    }
}

function submitUpdateNews (news, error=null) {
    return {
        type: UPDATE_NEWS,
        news,
        error
    }
}

export function handleSubmitUpdateNews(news) {
    return (dispatch) => {
        return updateNews(news)
        .then(() => {
            return dispatch(submitUpdateNews(news))    
        }).catch(error => {
            return dispatch(submitUpdateNews(undefined,error))  
        })
    }
}

export function handleSubmitGetNewsUpdates() {
    return () => {
        return getRealtimeNews()
    }
}

