import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import cities from "./cities";
// import news from "./news";
import breakingNews from "./breakingNews";
import user from "./authedUser";

export default combineReducers({
    cities,
    // news,
    breakingNews,
    user,
    loadingBar: loadingBarReducer,
}) 
