import React , { Component } from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';

import { handleSubmitGetUpdates } from '../store/actionCreators/cities';

class Video extends Component {
    state = {
        city: {},
        muted: true
    }

    componentDidMount() {
        this.setState({
            city: this.props.city
        })
        let city = this.state.city;
        city !== {} && this.props.dispatch(handleSubmitGetUpdates(this.props.city))
        .onSnapshot((doc) => {
            let city = this.state.city;
            city.url = doc.data().url; 
            city.muted = doc.data().muted;   
            this.setState({city}); 
        });
    }

    render() {
        const city = this.state.city
        // console.log(city)
        return (
            <div key={city.id}>
                <div className="publish city-name mb-1">{city.arabic}</div>
                <ReactPlayer className="video-box" playing={true} loop={true} muted={city.muted} width="100%" height="" url={city.url} />
            </div>
        )
    }
}

export default connect()(Video);