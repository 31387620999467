import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export const firebaseConfig = {
    apiKey: "AIzaSyApmFSRsd76DeF97nyLoUO5V2JfTI277XE",
    authDomain: "ehsan-499f7.firebaseapp.com",
    projectId: "ehsan-499f7",
    storageBucket: "ehsan-499f7.appspot.com",
    messagingSenderId: "65728806573",
    appId: "1:65728806573:web:9f3244c717b83f7a7c1024"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export function getInitialData() {
    return Promise.all([
        db.collection("cities").orderBy('id', 'asc').get(),
        db.collection("news").doc("news").get(),
        db.collection("news").doc("breaking").get()
    ]).then(([cities, news, bNews]) => ({
        cities,
        news,
        bNews
    }))
}

export function getAllCities() {
    return db.collection("cities").orderBy('id', 'asc').get();
}

export function getCity(city) {
    return db.collection("cities").doc(city.name).get()
}

export function updateCity(city) {
    return db.collection("cities").doc(city.name).set({
        ...city
    }, { merge: true });
}

export function updateCities(cities) {
    let error = null
    cities.forEach(city => {
        getCity(city)
        .then(doc => {
            if (doc.data().url !== city.url) {
                return db.collection("cities").doc(city.name).set({
                    ...city
                }, { merge: true })
                .then(() => console.log("Done"))
                .catch(error => error)
            }
        })
    })  
    return new Promise((res, rej) => {
        return res({error})
    })
}

export function getRealtimeUpdates(city) {
    return db.collection("cities").doc(city.name)
}

export function signIn (email,password) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
}  

export function logout() {
    return firebase.auth().signOut()
}

export function getNews() {
    return db.collection("news").doc("news").get();
}

export function updateNews(news) {
    return db.collection("news").doc("news").set({...news});
}

export function getRealtimeNews() {
    return db.collection("news").doc("news")
}

export function getBreakingNews() {
    return db.collection("news").doc("breaking").get();
}

export function updateBreakingNews(breakingNews) {
    return db.collection("news").doc("breaking").set({...breakingNews});
}

export function getRealtimeBreakingNews() {
    return db.collection("news").doc("breaking")
}

/*
function generateUID () {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

export function getShownCities() {
    return db.collection("cities").where("hidden","==",  false).orderBy('id', 'asc').get();
}
*/