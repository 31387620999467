import { GET_CITIES,UPDATE_CITIES, UPDATE_CITY, GET_UPDATES } from '../actionCreators/cities';

export default function cities (state = null, action) {
    switch (action.type) {
        case GET_CITIES :
            return action.cities;
        case UPDATE_CITIES :
            return action.cities === undefined ? state : action.cities;
        case UPDATE_CITY :
            return action.cities
        case GET_UPDATES :
            state.find(c => c.name ===action.city.name).city.url = action.city.url;
            state.find(c => c.name ===action.city.name).city.muted = action.city.muted;
            return state
        default :
            return state
    }
} 