import React , { Component } from 'react';
import { connect } from 'react-redux';
import Loading from './loading';
import Video from './video';
// import NewsTicker from './newsTicker';

import { handleSubmitGetUpdates } from '../store/actionCreators/cities';
// import { handleSubmitGetNewsUpdates } from '../store/actionCreators/news';
import { handleSubmitGetBreakingNewsUpdates } from '../store/actionCreators/breakingNews';

class Home extends Component {
    state = {
        cities: [],
        display: "breaking-news visible",
        news: {},
        breakingNews: {}
    }

    componentDidMount() {
        this.setState({cities: this.props.cities})
        this.props.cities !== null && this.props.cities.map(city => {
            return this.props.dispatch(handleSubmitGetUpdates(city))
            .onSnapshot((doc) => {
                let cities = this.state.cities;
                if (cities.find(c => c.name === doc.data().name).hidden !== doc.data().hidden || cities.find(c => c.name === doc.data().name).url !== doc.data().url) {  
                    cities.find(c => c.name === doc.data().name).url = doc.data().url; 
                    cities.find(c => c.name === doc.data().name).hidden = doc.data().hidden;    
                    this.setState({cities}); 
                }
            });
        })
        this.setState({
            breakingNews: this.props.breakingNews
        })
        this.setState({display: this.props.breakingNews !== null && this.props.breakingNews.hidden === false ? "breaking-news visible" : "invisible"}) 
        /*
        this.props.dispatch(handleSubmitGetNewsUpdates())
        .onSnapshot((doc) => {
            this.setState({news: doc.data()});
        });
        */
        this.props.dispatch(handleSubmitGetBreakingNewsUpdates())
        .onSnapshot((doc) => {
            if (this.state.breakingNews!==null && doc.data().hidden !== this.state.breakingNews.hidden) {
                this.setState({
                    breakingNews: doc.data()
                }); 
            } else {
                this.setState({
                    display: "invisible",
                    breakingNews: doc.data()
                }); 
                setTimeout(
                    () => this.setState({
                        display: this.state.breakingNews !== null && this.state.breakingNews.hidden === true ? "invisible" : "breaking-news visible"
                        // display: this.props.cities !== null && (this.state.cities.filter(c => c.hidden ===false).length !== 1 || (this.state.breakingNews !== null && this.state.breakingNews.hidden === true)) ? "invisible" : "breaking-news visible"
                    }), 
                    500
                )
            }
            
        });
    }

    toggleMuted = city => {
        let cities = this.state.cities;
        this.props.dispatch(handleSubmitGetUpdates(city))
        .onSnapshot((doc) => {
            if (doc.data().muted !== cities.find(c => c.name === doc.data().name).muted) {
                cities.find(c => c.name === doc.data().name).muted = doc.data().muted;    
                this.setState({cities});
            }
        });
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     return true
    // }
    /*
    changeOrder = e => {
        [].map.call(
            document.getElementsByClassName("box"),
            function (currentValue, index, collection) {
                console.log(collection.length)
                return currentValue.classList.remove("order-1");
            }
        );
        e.target.parentElement.parentElement.classList.add("order-1")
    }
    */
    setClassName = no => {
        /*
        if (no ===1 && this.state.breakingNews!== null && this.state.breakingNews.hidden === false) {
            
            return "box box-1-1 text-center";
        }
        */
        switch (no) {
            case 1 :
                return "box box-1 text-center";
            case 2 :
                return "box col-lg-6 box-2 text-center";
            case 3 :
            case 4 :
                return "box col-lg-6 box-3 text-center";
            case 5 :
            case 6 :
                return "box col-md-6 col-lg-4 box-5 text-center";
            case 7 :
            case 8 :
            case 9 :
                return "box col-md-6 col-lg-4 box-7 text-center";
            case 10 :
            case 11 :
            case 12 :
                return "box col-md-4 col-lg-3 box-10 text-center";
            case 13 :
            case 14 :
                return "box col-md-4 col-lg-3 box-13 text-center";
            default :
                return "box";
        }
    }
    
    render() {
        if (this.props.loadingBar.default || this.props.cities===null || this.state.cities===null) return <Loading />
        else { 
            const activeCities = this.state.cities.filter(c => c.hidden === false)
            // console.log(this.state)
            return (
                <>
                {/* {this.state.news !== null && this.state.news.hidden === false && <NewsTicker />} */}
                {this.state.breakingNews!== null && this.state.breakingNews.hidden === true
                    ? null
                    : <div className="breaking-news-container">
                        <div className="row breaking-news-row">
                            <div className="breaking-news-logo">
                                <img src="logo512.png" alt="logo" width="100%" />
                            </div>
                            <div className={this.state.display}>
                                <h5>{this.state.breakingNews!== null && this.state.breakingNews.news}</h5>
                            </div>
                        </div>
                    </div>
                }
                <div className="videos row">
                    {activeCities.map(city => 
                        <div className={this.setClassName(activeCities.length)} key={city.id}>
                            {city.url === ""
                                ?   <div className="waiting">
                                        <div className="publish city-name mb-1">{city.arabic}</div>
                                        <div style={{cursor: "none", position: "relative", height: "100%"}}>
                                            <p>لم يتم إضافة رابط لهذه المدينة</p>
                                        </div>
                                    </div>
                                :   <Video city={city}/>
                            }
                        </div>
                    )} 
                </div>
                </>
            )
        }
    }
}

function mapStateToProps ({ cities, loadingBar, breakingNews }) {
	return {
        cities, loadingBar, breakingNews
	}
}

export default connect(mapStateToProps)(Home);