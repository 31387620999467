import React , { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Alert, Modal, Button } from 'react-bootstrap';
import Loading from './loading';
import { handleSubmitGetCity, handleSubmitUpdateCity } from '../store/actionCreators/cities';
import { handleSignIn, handleLogout, handleSetAuthedUser } from '../store/actionCreators/authedUser';
// import { handleSubmitUpdateNews } from '../store/actionCreators/news';
import { handleSubmitUpdateBreakingNews } from '../store/actionCreators/breakingNews';

class Control extends Component {
    state = {
        cities: [],
        news: {},
        breaking: {},
        error: "",
        alertLoginError: false,
        alertError: false,
        password: "",
        path: {
            show: "M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035 c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201 C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418 c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418 C447.361,287.923,358.746,385.406,255.997,385.406z M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275 s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516 s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z",
            hide: "M474.609,228.901c-29.006-38.002-63.843-71.175-103.219-98.287l67.345-67.345c6.78-6.548,6.968-17.352,0.42-24.132 c-6.548-6.78-17.352-6.968-24.132-0.42c-0.142,0.137-0.282,0.277-0.42,0.42l-73.574,73.506 c-31.317-17.236-66.353-26.607-102.093-27.307C109.229,85.336,7.529,223.03,3.262,228.9c-4.349,5.983-4.349,14.087,0,20.07 c29.006,38.002,63.843,71.175,103.219,98.287l-67.345,67.345c-6.78,6.548-6.968,17.352-0.42,24.132 c6.548,6.78,17.352,6.968,24.132,0.42c0.142-0.137,0.282-0.277,0.42-0.42l73.574-73.506 c31.317,17.236,66.353,26.607,102.093,27.307c129.707,0,231.407-137.694,235.674-143.565 C478.959,242.988,478.959,234.884,474.609,228.901z M131.296,322.494c-34.767-23.156-65.931-51.311-92.484-83.558 c25.122-30.43,106.598-119.467,200.124-119.467c26.609,0.538,52.77,6.949,76.612,18.773L285.92,167.87 c-39.2-26.025-92.076-15.345-118.101,23.855c-18.958,28.555-18.958,65.691,0,94.246L131.296,322.494z M285.016,217.005 c3.34,6.83,5.091,14.328,5.12,21.931c0,28.277-22.923,51.2-51.2,51.2c-7.603-0.029-15.101-1.78-21.931-5.12L285.016,217.005z M192.856,260.866c-3.34-6.83-5.091-14.328-5.12-21.931c0-28.277,22.923-51.2,51.2-51.2c7.603,0.029,15.101,1.78,21.931,5.12 L192.856,260.866z M238.936,358.402c-26.609-0.538-52.769-6.949-76.612-18.773l29.628-29.628 c39.2,26.025,92.076,15.345,118.101-23.855c18.958-28.555,18.958-65.691,0-94.246l36.523-36.523 c34.767,23.156,65.931,51.312,92.484,83.558C413.937,269.366,332.461,358.402,238.936,358.402z"
        },
        movePath: {
            play: "M295.84,146.049l-256-144c-4.96-2.784-11.008-2.72-15.904,0.128C19.008,5.057,16,10.305,16,16.001v288 c0,5.696,3.008,10.944,7.936,13.824c2.496,1.44,5.28,2.176,8.064,2.176c2.688,0,5.408-0.672,7.84-2.048l256-144 c5.024-2.848,8.16-8.16,8.16-13.952S300.864,148.897,295.84,146.049z",
            pause: "M193.441,0h-75.484c-16.897,0-30.6,13.703-30.6,30.6v458.277c0,16.898,13.703,30.602,30.6,30.602h75.484 c16.897,0,30.6-13.703,30.6-30.602V30.6C224.042,13.703,210.339,0,193.441,0z M401.521,0h-75.484c-16.896,0-30.6,13.703-30.6,30.6v458.277c0,16.898,13.703,30.602,30.6,30.602h75.484 c16.896,0,30.6-13.703,30.6-30.602V30.6C432.121,13.703,418.418,0,401.521,0z"
        },
        openClearModal: false,
    }

    componentDidMount() {
        this.props.dispatch(handleSetAuthedUser())
        this.setState({
            cities: this.props.cities,
            // news: this.props.news,
            breaking: this.props.breakingNews
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.cities !== this.props.cities) {
            this.setState({
                cities: this.props.cities
            })
        }
        /*
        if (prevProps.news !== this.props.news) {
            this.setState({
                news: this.props.news
            })
        }
        */
        if (prevProps.breakingNews !== this.props.breakingNews) {
            this.setState({
                breaking: this.props.breakingNews
            })
        }
    }

    handleChange = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState(() => ({
            alertLoginError: false,
            error: ''
        }))
        const { password } = this.state;
        const { dispatch } = this.props;
        const email = "amribrahim11@gmail.com";
        
        dispatch(handleSignIn(email, password))
        .then((result) => {
            if (result.error===null) {
            } else {
                this.setState(() => ({
                    error: "كلمة سر خاطئة",
                    alertLoginError: true,
                }))
            }
        })
    }
    /*
    handleChangeNews = e => {
        this.setState({
            news: {...this.state.news, news: e.target.value}
        })
	}
    handleChangeNewsHidden = () => {
        this.setState({news: {...this.state.news, hidden: !this.state.news.hidden}})
    }
    handleChangeMove = () => {
        this.setState({news: {...this.state.news, move: !this.state.news.move}})
    }
    */
    handleChangeBreakingNews = e => {
        this.setState({
            breaking: {...this.state.breaking, news: e.target.value}
        })
    }
    handleChangeBrokenHidden = () => {
        this.setState({breaking: {...this.state.breaking, hidden: !this.state.breaking.hidden}})
    }
    
	handleChangeLink = e => {
		let cities = this.state.cities;
        let city = this.state.cities.find(c => c.name === e.target.name)
        city.url = e.target.value
        this.setState(() => ({
			cities
		}))
	}

    handleChangeMuted = e => {
        let cities = this.state.cities;
        let city = this.state.cities.find(c => c.name === e.target.name)
        city.muted = !city.muted
        this.setState(() => ({
			cities
		}))
    }

    handleChangeHidden = name => {
        let cities = this.state.cities;
        let city = this.state.cities.find(c => c.name === name)
        city.hidden = !city.hidden
        this.setState(() => ({
			cities
		}))
    }

    setOpenClearModal = value => this.setState({openClearModal: value})

    clearAll = () => {
        let cities = this.state.cities;
        cities.forEach(city => {
            city.url = "";
            city.muted = true;
            city.hidden = true;
        })
        return this.setState(() => ({cities}))
    }

    updateCities = () => {
        this.setState({
            alertError: false,
            error: ""                      
        })
        let { cities, breaking } = this.state;
        // this.props.dispatch(handleSubmitUpdateCities(cities))
        // .then(result => console.log(result))
        cities.forEach((city) => {
            this.props.dispatch(handleSubmitGetCity(city))
            .then(doc => {
                if (doc.data().url !== city.url || doc.data().muted !== city.muted || doc.data().hidden !== city.hidden) {
                    this.props.dispatch(handleSubmitUpdateCity(city))
                    .then(result => {
                        if (result.error !== null) {
                            this.setState({
                                error: result.error.message,
                                alertError: true
                            })
                        }
                    })
                }
            })
        })
        /*
        if (this.props.news !== news) {
            this.props.dispatch(handleSubmitUpdateNews(news))
            .then(result => {
                if (result.error !== null) {
                    this.setState({
                        news: this.props.news,
                        error: result.error.message,
                        alertError: true
                    })
                }
            })
        }
        */
        if (this.props.breakingNews !== breaking) {
            this.props.dispatch(handleSubmitUpdateBreakingNews(breaking))
            .then(result => {
                if (result.error !== null) {
                    this.setState({
                        breaking: this.props.breaking,
                        error: result.error.message,
                        alertError: true
                    })
                }
            })
        }
    }

    logout = () => {
        this.props.dispatch(handleLogout())
        .then(result => {
            if (result.error === null) {
                // window.location.href="/"
            }
        })
    }
    
    render() {
        // console.log(this.state)
        if (this.props.loadingBar.default || this.props.cities===null || this.state.cities === null) return <Loading />
        else {
            if (!this.props.user || this.props.user === null || this.props.user.email !== "amribrahim11@gmail.com") {
                return (
                    <Modal
                        show = {true}
                        aria-labelledby="login-modal"
                        className="login"
                    >
                        <Modal.Header  className="m-auto">
                        <Modal.Title id="example-modal-sizes-title-sm">
                            تسجيل الدخول
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-wrapper">
                                <Form className="text-center" onSubmit={this.handleSubmit}>
                                    <Form.Group>
                                        <Form.Control type="password" className="text-center" name="password" placeholder="كلمة السر" onChange={this.handleChange} required />
                                        <span className="required-indicator" role="presentation"></span>
                                    </Form.Group>
                                    <Button className="btn btn--primary" type="submit" >دخول</Button>
                                </Form>
                            </div>
                            <Alert show={this.state.alertLoginError} className="text-center m-2" variant='danger'>{this.state.error}</Alert>
                        </Modal.Body>
                    </Modal>
                )
            } else {
                // console.log(this.state)
                return (
                    <div className="control">
                    <div className="container">
                        <div className="row justify-content-between">
                            <button className="btn clear" onClick={this.logout}>Log Out</button>
                            <Link to="/" className="btn clear">Home</Link>
                        </div>
                        {/* <div className="m-2 text-center news-form">
                            <svg title="Show/Hide" onClick={this.handleChangeNewsHidden} version="1.1" id="Capa_1" width="18" fill="#fff" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 477.871 477.871" style={{enableBackground:"new 0 0 477.871 477.871", cursor:"pointer", float: "left"}} >
                                <path d={this.state.news.hidden ? this.state.path.hide : this.state.path.show}/>
                            </svg>
                            <svg title="Play/Pause" onClick={this.handleChangeMove} version="1.1" id="Capa_1" width="18" fill="#fff" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 477.871 477.871" style={{enableBackground:"new 0 0 477.871 477.871", cursor:"pointer", float: "left", margin: "3px 20px"}} >
                                <path d={this.state.news.move ? this.state.movePath.pause : this.state.movePath.play}/>
                            </svg>
                            <label htmlFor="exampleInputEmail1" className="form-label"><h5>شريط الأخبار</h5></label>
                            <input type="text" className="form-control text-center" aria-describedby="news" name="news" value={this.state.news.news} onChange={this.handleChangeNews} />
                        </div> */}
                        <div className="m-2 text-center news-form">
                            <svg title="Show/Hide" onClick={this.handleChangeBrokenHidden} version="1.1" id="Capa_1" width="18" fill="#fff" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 477.871 477.871" style={{enableBackground:"new 0 0 477.871 477.871", cursor:"pointer", float: "left"}} >
                                <path d={this.state.breaking.hidden ? this.state.path.hide : this.state.path.show}/>
                            </svg>
                            <label htmlFor="exampleInputEmail1" className="form-label"><h5>Breaking News</h5></label>
                            <input type="text" className="form-control text-center" aria-describedby="breaking" name="breaking" value={this.state.breaking.news} onChange={this.handleChangeBreakingNews} />
                        </div>
                        <div className="control-row row justify-content-center">
                            {this.state.cities.map(city =>
                                <div className="city row col-lg-5 justify-content-around" key={city.id}>
                                    <div className="col-4 text-center city-name">
                                        <h5>{city.arabic}</h5>
                                    </div>
                                    <div className="col-8 text-center">
                                        <Form inline className="justify-content-around">
                                            <input type="text" name={city.name} value={city.url} onChange={this.handleChangeLink} placeholder="URL" />
                                            <Form.Check className=""
                                                checked={!city.muted}
                                                type="switch"
                                                value={city.muted}
                                                id={city.name}
                                                name={city.name}
                                                title="audio"
                                                onChange={this.handleChangeMuted}
                                            />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#fff" className="bi bi-mic" viewBox="0 0 16 16">
                                                <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
                                                <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"/>
                                            </svg>
                                            <svg title="Show/Hide" onClick={() => this.handleChangeHidden(city.name)} version="1.1" id="Capa_1" width="18" fill="#fff" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 477.871 477.871" style={{enableBackground:"new 0 0 477.871 477.871", cursor:"pointer"}} >
                                                <path d={city.hidden ? this.state.path.hide : this.state.path.show}/>
                                            </svg>
                                        </Form>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="row teaxt-center m-auto justify-content-center">
                            <button className="btn publish" onClick={this.updateCities}>Publish</button>
                            <button className="btn clear" onClick={() => this.setOpenClearModal(true)}>Clear all</button>
                        </div>
                        <Alert show={this.state.alertError} style={{direction: "ltr"}} className="text-center m-2" variant='danger'>{this.state.error}</Alert>
                        <ClearModal
                            clearModal={this.state.openClearModal}
                            closeClearModal={() => this.setOpenClearModal(false)}
                            clearAll = {this.clearAll}
                        />
                    </div>
                    </div>
                )
                
            } 
        }
        
    }
}

function mapStateToProps ({ cities, loadingBar, user, news, breakingNews }) {
	return {
        cities, loadingBar, user, news, breakingNews
	}
}

export default connect(mapStateToProps)(Control);

class ClearModal extends Component {

    closeModal = () => this.props.closeClearModal()
    clear = () => {
        this.props.clearAll()
        this.props.closeClearModal()
    }
    render() {
        // console.log(this.props)
        const { clearModal } = this.props;
        return (
            <Modal
                show={clearModal}
                onHide={this.closeModal}
                aria-labelledby="clear-modal"
                className="clear-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Clear All</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5><Alert variant="warning text-center">هل أنت متأكد من حذف كل الروابط؟</Alert></h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.closeClearModal}>Cancel</Button>
                    <Button variant="danger" onClick={this.clear}>Clear all</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}